import React from 'react';
import PropTypes from 'prop-types';
import NotificationBell from './notification-bell-today.svg';
import styles from './styles.module.scss';

export const TodayPrompt = ({
  acceptAction = () => {},
  declineAction = () => {},
}) => (
  <div
    className={styles.softOptin}
  >
    <button
      type="button"
      className={styles.closeButton}
      onClick={() => declineAction('exit')}
    >
      <div className={styles.close} />
    </button>
    <div className={styles.content}>
      <div className={styles.icon}>
        <img alt="Notification bell icon" src={NotificationBell} />
      </div>
      <div className={styles.body}>
        <h3 className={styles.title}>Don&apos;t miss a thing!</h3>
        <div className={styles.description}>
          Sign up for Shop TODAY notifications to get the latest deals,
          trends, expert-approved picks and more!
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.decline}
            type="button"
            onClick={() => declineAction('decline')}
          >
            Maybe Later
          </button>
          <button
            className={styles.accept}
            type="button"
            onClick={acceptAction}
          >
            Allow
          </button>
        </div>
      </div>
    </div>
  </div>
);

TodayPrompt.propTypes = {
  acceptAction: PropTypes.func,
  declineAction: PropTypes.func,
};
